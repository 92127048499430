import { render, staticRenderFns } from "./InfoComponent.vue?vue&type=template&id=a65a27aa&scoped=true"
import script from "./InfoComponent.vue?vue&type=script&lang=js"
export * from "./InfoComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a65a27aa",
  null
  
)

export default component.exports