var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"catalogs"},[_c('v-btn',{staticClass:"mb-5 mt-2",attrs:{"text":"","depressed":"","router":"","to":`/projects/${_vm.$router.currentRoute.params.projectID}/channels/${_vm.$router.currentRoute.params.channelID}/print/catalogs`}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("arrow_back")]),_vm._v(" alle Versionen ")],1),_c('p',{staticClass:"text-body-1 grey--text mt-4 mb-0 text-lowercase"},[_vm._v(" "+_vm._s(_vm.currentCatalog._id)+" ")]),_c('p',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.currentCatalog.name)+" "),(_vm.$permGuard.chP('entries.u'))?_c('v-dialog',{attrs:{"width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"black","fab":"","depressed":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" edit_note ")])],1)]}}],null,false,2507654139),model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-0"},[_vm._v(" Informationen ")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"text--h3 pt-0",attrs:{"label":"Name","value":_vm.currentCatalog.name,"loading":_vm.loading},on:{"input":_vm.updateName}})],1)],1),(_vm.advancedCatalogMetaInfo)?_c('v-row',_vm._l((_vm.advancedMetaData),function(prop,index){return _c('v-col',{key:index,staticClass:"pt-0 mt-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"text--h3 pt-0",attrs:{"label":prop.label,"loading":_vm.loading},on:{"input":function($event){return _vm.update(prop.key, $event)}},model:{value:(
                                    (_vm.currentCatalog.info || {})[prop.key]
                                ),callback:function ($$v) {_vm.$set((_vm.currentCatalog.info || {}), prop.key, $$v)},expression:"\n                                    (currentCatalog.info || {})[prop.key]\n                                "}})],1)}),1):_vm._e(),_c('span',{staticClass:"text-overline grey--text"},[_vm._v("Ansprechpartner")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mb-0"},[_vm._v("VENUS GmbH")]),_c('UserSelect',{attrs:{"uuid":_vm.currentCatalog.assignedAdmin},on:{"change":_vm.updateAssignedAdmin}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mb-0"},[_vm._v("Kunde")]),_c('UserSelect',{attrs:{"uuid":_vm.currentCatalog.assignedCustomer},on:{"change":_vm.updateAssignedCustomer}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.infoDialog = false}}},[_vm._v("OK")])],1)],1)],1):_vm._e()],1),(
            _vm.$permGuard.chP('entries.c') &&
            _vm.$permGuard.chP('actions.sync_catalog')
        )?_c('CatalogSync',{attrs:{"catalogId":this.$router.currentRoute.params.catalogID},on:{"synced":function($event){return _vm.fetchEntriesByCatalog(_vm.$router.currentRoute.params.catalogID)}},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","depressed":"","color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Import ")])]}}],null,false,3858575796)}):_vm._e(),(
            _vm.$permGuard.chP('entries.r') &&
            _vm.$settings.getValue('accounting.enabled')
        )?_c('v-btn',{staticClass:"ml-3",attrs:{"rounded":"","depressed":"","color":"orange","loading":_vm.downloadingBilling},on:{"click":_vm.downloadBillingCSV}},[_c('span',{staticClass:"white--text"},[_vm._v("Rechnungsinfos")]),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"white"}},[_vm._v("receipt_long")])],1):_vm._e(),(_vm.$permGuard.chP('super_admin'))?_c('DeleteConfirmation',{attrs:{"title":"Daten wirklich in Graph schreiben?"},on:{"yes":_vm.exportToGraph},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"depressed":"","rounded":"","color":"error","loading":_vm.loadingGraphExport}},'v-btn',attrs,false),on),[_vm._v(" Graph-Export ")])]}}],null,false,1233006806)}):_vm._e(),(_vm.$permGuard.chP('actions.get_xml'))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"loading":_vm.downloadingXML,"color":"primary","depressed":"","dark":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v("XML"),_c('v-icon',[_vm._v("expand_more")])],1)]}}],null,false,1196158351)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.downloadExport}},[_c('v-list-item-title',[_vm._v("Download (Alle)")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadExport({ onlyConfirmed: true })}}},[_c('v-list-item-title',[_vm._v("Download (Freigegeben)")])],1),_c('v-list-item',{attrs:{"href":`/api/export/catalog/${
                    _vm.currentCatalog._id
                }?format=xml&languages=${_vm.availableLanguages.join(',')}`,"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Ansehen (Alle)")])],1),_c('v-list-item',{attrs:{"href":`/api/export/catalog/${
                    _vm.currentCatalog._id
                }?format=xml&statusPolicy=confirmed&languages=${_vm.availableLanguages.join(
                    ','
                )}`,"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Ansehen (Freigegeben)")])],1)],1)],1):_vm._e(),(
            _vm.$permGuard.chP('actions.generate_catalog') &&
            !_vm.$settings.getValue('cometConnection.sorting.enabled')
        )?_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","rounded":"","color":"secondary","loading":_vm.loadingCometCatalog},on:{"click":_vm.generateCometCatalog}},[_vm._v(" Gesamtdokument "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("send_and_archive")])],1):_vm._e(),(_vm.$permGuard.chP('actions.convert_status'))?_c('StatusRuleBuilder',{on:{"convert":_vm.convertEntryStatus},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"rounded":"","depressed":"","color":"info"}},'v-btn',attrs,false),on),[_vm._v(" Status konvertieren "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("nat")])],1)]}}],null,false,1829648472)}):_vm._e(),(_vm.loadingGraphExport)?_c('div',{staticClass:"my-5 py-5",staticStyle:{"max-width":"1200px"}},[_c('p',{staticClass:"mt-1 mb-1"},[_vm._v("Datensätze werden zurückgeschrieben...")]),_c('v-progress-linear',{attrs:{"rounded":"","color":"success","indeterminate":""}})],1):_vm._e(),(
            _vm.$permGuard.chP('entries.c') &&
            _vm.$settings.getValue('cometConnection.sorting.enabled')
        )?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 white--text",attrs:{"depressed":"","rounded":"","color":"purple"}},'v-btn',attrs,false),on),[_vm._v(" Sortierung "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("sort")])],1)]}}],null,false,2162151407)},[_c('v-list',{staticClass:"pa-0",attrs:{"max-width":"500","width":"300"}},[_vm._l((_vm.currentCatalog.sortDefinitions),function(item,index){return [_c('v-list-item',{key:index,attrs:{"dense":"","append":"","to":`sort/${item.id}`}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                item.name || 'Sortierung ' + (index + 1)
                            )}}),_c('v-list-item-subtitle',[_vm._v("("+_vm._s(item.id)+")")])],1)],1),_c('v-divider',{key:index})]}),_c('v-row',{staticClass:"ma-0"},[_c('v-btn',{staticClass:"my-2 ml-2",attrs:{"rounded":"","small":"","depressed":"","color":"success","append":"","to":`sort/${Math.round(Math.random() * 100000000)}`}},[_vm._v("Neu")]),_c('v-spacer')],1)],2)],1):_vm._e(),_c('v-divider',{staticClass:"my-6"}),(_vm.allEntries.length > 0)?_c('apexchart',{attrs:{"height":"150","width":"500","type":"donut","options":_vm.chartOptions,"series":_vm.statusStats}}):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-h5"},[_vm._v("Einträge:")]),_c('EntryList',{ref:"chart"})],1),(
            _vm.$settings.getValue('general.create.mode') > 0 &&
            _vm.$permGuard.chP('entries.c')
        )?_c('EntryCreate',{ref:"entryCreate"}):_vm._e(),_c('DeleteConfirmation',{ref:"redirectDialog",attrs:{"hidden":true,"msg":"Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?","title":"Jetzt bearbeiten?"}},[void 0],2),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"success","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Gespeichert! ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }