var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{staticClass:"dragArea",attrs:{"tag":"div","list":_vm.items,"group":{ name: 'g1' },"bind":_vm.dragOptions,"options":{ swapThreshold: 0.8 }},on:{"start":function($event){_vm.drag = true},"end":_vm.endDrag}},[_vm._l((_vm.items),function(item,index){return [_c('v-expansion-panels',{key:item.name + index,attrs:{"multiple":"","accordion":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"px-4",scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
                                (_vm.$settings.getValue('language.available')
                                    .length || []) > 1
                            )?_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"transparent","depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"transform":"none"}},[_vm._v(" content_copy ")])],1)]}}],null,true),model:{value:(item.cloneDialog),callback:function ($$v) {_vm.$set(item, "cloneDialog", $$v)},expression:"item.cloneDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Duplizieren? ")]),_c('v-card-text',{staticClass:"mt-5"},[_vm._v(" In welche Sprache duplizieren: "),_c('v-radio-group',{attrs:{"mandatory":""},model:{value:(_vm.duplicateTargetLangCode),callback:function ($$v) {_vm.duplicateTargetLangCode=$$v},expression:"duplicateTargetLangCode"}},_vm._l((_vm.$settings.getValue(
                                                'language.available'
                                            )),function(lang){return _c('v-radio',{key:lang,attrs:{"label":lang,"value":lang},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-avatar',{staticClass:"ml-2",attrs:{"size":"18"}},[_c('v-img',{attrs:{"src":`https://webimages.we2p.de/static/flags/svg/${lang.slice(
                                                            -2
                                                        )}.svg`}})],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.getLangName(lang)))])]},proxy:true}],null,true)})}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){item.cloneDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.copyItem(
                                                item,
                                                index,
                                                _vm.duplicateTargetLangCode
                                            )}}},[_vm._v(" Duplizieren ")])],1)],1)],1):_vm._e(),(_vm.nextLevel > 1)?_c('CometPreview',{attrs:{"entry-id":item._id,"languages":[item.lang]},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"bind":attrs,"depressed":"","fab":"","x-small":""}},on),[_c('v-icon',{staticStyle:{"transform":"none"},attrs:{"color":"info"}},[_vm._v(" remove_red_eye ")])],1)]}}],null,true)}):_vm._e(),(
                                ((item.printStatus || {}).buildPaths || [])
                                    .length > 0 &&
                                (item.printStatus || {}).buildHash ===
                                    item.currentHash
                            )?_c('CometPreview',{attrs:{"urls":(item.printStatus || {}).buildPaths},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({attrs:{"bind":attrs,"depressed":"","fab":"","x-small":""}},on),[_c('v-icon',{staticStyle:{"transform":"none"}},[_vm._v(" remove_red_eye ")])],1)]}}],null,true)}):_vm._e(),(_vm.nextLevel === 1)?_c('DeleteConfirmation',{attrs:{"title":`Katalog-Segment generieren?`,"text":"Das Segement wird generiert und für das Gesamtdokument gespeichert."},on:{"yes":function($event){return _vm.buildConfirmation(index)}},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":"","text":"","bind":attrs,"loading":_vm.loading[index]}},on),[(
                                            (item.printStatus || {})
                                                .built &&
                                            (item.printStatus || {})
                                                .buildHash ===
                                                item.currentHash
                                        )?_c('v-icon',{staticStyle:{"transform":"none"},attrs:{"color":"success"}},[_vm._v(" check ")]):_vm._e(),(
                                            (item.printStatus || {})
                                                .built &&
                                            (item.printStatus || {})
                                                .buildHash !==
                                                item.currentHash
                                        )?_c('v-icon',{staticStyle:{"transform":"none"},attrs:{"color":"warning"}},[_vm._v(" warning ")]):_vm._e(),(
                                            !(item.printStatus || {}).built
                                        )?_c('v-icon',{staticStyle:{"transform":"none"},attrs:{"color":"info","size":"24"}},[_vm._v(" build_circle ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('DeleteConfirmation',{attrs:{"title":`${
                                item.children.length > 0
                                    ? 'Die Gruppe'
                                    : 'Den Eintrag'
                            } aus der Planung entfernen?`},on:{"yes":function($event){return _vm.remove(index)}},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","depressed":"","text":"","bind":attrs}},on),[_c('v-icon',{staticStyle:{"transform":"none"},attrs:{"color":"error","size":"20"}},[_vm._v(" delete_forever ")])],1)]}}],null,true)}),_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","depressed":"","text":""}},[_c('v-badge',{attrs:{"content":item.children.length,"value":item.children.length > 0,"color":"grey"}},[_c('v-icon',[_vm._v(" $expand ")])],1)],1)]},proxy:true}],null,true)},[_c('p',{staticClass:"my-0",staticStyle:{"line-height":"1.5"}},[_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":"","nudge-left":"12px","nudge-bottom":"4px","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                                        (_vm.$settings.getValue(
                                            'language.available'
                                        ).length || []) > 1
                                    )?_c('span',[(item.lang)?_c('v-avatar',_vm._g({staticClass:"mr-2",attrs:{"size":"14"}},on),[_c('v-img',{attrs:{"src":`https://webimages.we2p.de/static/flags/svg/${item.lang.slice(
                                                -2
                                            )}.svg`}})],1):_vm._e()],1):_vm._e()]}}],null,true)},[_c('v-card',{staticClass:"py-1 pl-3 pr-5"},[_vm._l((_vm.getNames(
                                        _vm.$store.getters[
                                            'entries/allEntries'
                                        ].find(
                                            (e) =>
                                                e._id.toString() ===
                                                item._id
                                        ) || {},
                                        item.lang
                                    )),function(name,index){return [_c('span',{key:index},[(
                                                (_vm.$settings.getValue(
                                                    'language.available'
                                                ).length || []) > 1
                                            )?_c('span',[(item.lang)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"14"}},[_c('v-img',{attrs:{"src":`https://webimages.we2p.de/static/flags/svg/${name.lang.slice(
                                                        -2
                                                    )}.svg`}})],1):_vm._e()],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(name.value)+" ")]),_c('br')])]})],2)],1),_c('span',{class:`${
                                !_vm.$store.getters['entries/allEntries'].find(
                                    (e) => e._id.toString() === item._id
                                )
                                    ? 'error--text'
                                    : ''
                            }`},[_vm._v(_vm._s(item.name))]),_c('v-dialog',{attrs:{"persistent":"","max-width":"1800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"depressed":"","x-small":"","text":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" edit ")])],1)]}}],null,true),model:{value:(item.dialogOpened),callback:function ($$v) {_vm.$set(item, "dialogOpened", $$v)},expression:"item.dialogOpened"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"red","icon":""},on:{"click":function($event){item.dialogOpened = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('EntryEdit',{attrs:{"minimal":true,"entry-id":item._id},on:{"save":function($event){return _vm.$emit('entrySave')}}})],1)],1)],1),(
                                !_vm.$store.getters['entries/allEntries'].find(
                                    (e) => e._id.toString() === item._id
                                )
                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" warning ")])]}}],null,true)},[_c('span',[_vm._v("Der Eintrag existiert nicht mehr.")])]):_vm._e(),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("Seite: "+_vm._s(item.page.firstPage)+" ("+_vm._s(_vm.getSide(item) === 'left' ? 'links' : 'rechts')+")")]),(!_vm.hideTemplates)?[_c('br'),_c('span',{staticClass:"grey--text caption"},[_vm._v(_vm._s(( _vm.$store.getters['templates/getAll'].find( (t) => t._id.toString() === item.templateID ) || {} ).name))])]:_vm._e()],2),(!_vm.hideTemplates)?_c('img',{staticClass:"mr-5",staticStyle:{"max-height":"50px","max-width":"33%","object-fit":"contain","object-position":"right"},attrs:{"src":`https://cc.we2p.de/${_vm.$settings.getValue(
                            'cometConnection.client'
                        )}/INSTANCE1/pageitems/preview/${
                            (
                                _vm.$store.getters['templates/getAll'].find(
                                    (t) =>
                                        t._id.toString() === item.templateID
                                ) || {}
                            ).id
                        }.gif`}}):_vm._e()]),_c('v-expansion-panel-content',[_c('sort-definition-nested',{attrs:{"items":item.children,"level":_vm.nextLevel,"hide-templates":_vm.hideTemplates},on:{"move":function($event){return _vm.$emit('move')},"entrySave":function($event){return _vm.$emit('entrySave')},"save":_vm.save}})],1)],1)],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }