import { render, staticRenderFns } from "./System.vue?vue&type=template&id=4e3a6d54&scoped=true"
import script from "./System.vue?vue&type=script&lang=js"
export * from "./System.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e3a6d54",
  null
  
)

export default component.exports